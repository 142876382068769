import { GatsbyPageProps, Token } from '@/types';
import { ArticleList } from '../../articles';
import Xxx20WeekMaChartTemplate from '@/templates/crypto/xxx-20-week-moving-average-chart';

export default function Solana20WeekMaChart({ location }: GatsbyPageProps) {
  return (
    <Xxx20WeekMaChartTemplate
      coinAbbreviation="SOL"
      coinFullName="Solana"
      token={Token.SOLUSDT}
      location={location}
      articleId={ArticleList.SOLANA_20_WEEK_MOVING_AVERAGE_CHART}
    />
  );
}
